import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {

        $(".slider").map(function() {
            let desktop_slides_count = $(this).data('desktop_slides_count');
            let tablet_slides_count = $(this).data('tablet_slides_count');
            let mobile_slides_count = $(this).data('mobile_slides_count');

            let slider_config = {
                infinite: false,
                // centerMode: true,
                centerPadding: '5px',
                slidesToShow: desktop_slides_count,
                arrows: true,
                prevArrow: '<button type="button" class="slick-prev"><span class="dashicons dashicons-arrow-left-alt2"></span></button>',
                nextArrow: '<button type="button" class="slick-next"><span class="dashicons dashicons-arrow-right-alt2"></span></button>',
                responsive: [
                    {
                        breakpoint: 786,
                        settings: {
                            arrows: true,
                            // centerMode: true,
                            centerPadding: '2px',
                            slidesToShow: tablet_slides_count
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: true,
                            // centerMode: true,
                            centerPadding: '2px',
                            slidesToShow: mobile_slides_count
                        }
                    }
                ]
            };

            $(this).slick(slider_config);
        }).get();
    });
})(jQuery);
