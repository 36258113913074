import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        function updateDays() {
            let date = $(".rel-date").data("rel-dt");
            let sdate = new Date(date);
            let today = new Date();
            let days = (today - sdate) / (1000 * 3600 * 24);
            days = Math.trunc(days);
            let text = "";
            if (days < 30) {
                if (days < 7) {
                    text = days + (days > 1 ? " Days Ago" : " Day Ago");
                } else {
                    let weeks = Math.trunc(days / 7);
                    text = weeks + (weeks > 1 ? " Weeks Ago" : " Week Ago");
                }
            } else {
                let months = Math.trunc(days / 30);
                text = months + (months > 1 ? " Months Ago" : " Month Ago");
            }
            // text =
            //     Math.trunc(days) < 30
            //         ? Math.trunc(days) > 7
            //             ? "" + Math.trunc(Math.trunc(days) / 7) + " Weeks Ago"
            //             : "" + Math.trunc(days) + "Days Ago"
            //         : "" + Math.trunc(Math.trunc(days) / 30) + " Months Ago";

            $(".relase_day").text(text);
        }
        updateDays();
    });
})(jQuery);
