import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        let $progressBar = $(".progress");
        let $progressBarLabel = $(".slider__label");

        $(".tabs-list").click(function () {
            var sneappeek = $(this).closest('.wp-block-edwiseradvancedblock-sneakpeek'); // get parent element
            var tabid = "#" + $(this).data("tid");

            // Remove existing active class
            var existing_active_tabs = $( sneappeek ).find( ".tabs-list,.tab-content-wrap" ); 
            $(existing_active_tabs).removeClass("active");

            var selected_tab_content = $( sneappeek ).find( tabid ); // get tab conent element for selected tab 

            $(this).addClass("active");
            $(selected_tab_content).addClass("active");
        });

        $(".feature-img-wrap").click(function () {
            let progress = 0;
            let slideno = $(this).data("si");
            let heading = $(this).data("heading");

            var tab_content_wrap = $(this).closest('.tab-content-wrap'); // get parent element
            var edw_slider_wrap = tab_content_wrap.parent(); // get main wrap
            let slider = $(edw_slider_wrap).find(".ss-cont");
            var slider_popup = edw_slider_wrap.find('.sn-popup');

            slider.on("init", function (event, slick) {
                progress = (1 / slick.slideCount) * 100;
                updateSliderProgress(progress);
            });
            let headingWrap = $(edw_slider_wrap).find(".feture-cont h3");
            headingWrap.text(heading);

            let description = $(this).data("description");
            let descriptionWrap = $(edw_slider_wrap).find(".feture-cont p");
            descriptionWrap.text(description);

            let descriptionTitleWrap = $(edw_slider_wrap).find(".feture-cont h5");
            let description_title = $(this).data("description_title");
            descriptionTitleWrap.text(description_title);


            $(slider_popup).css("display", "flex");
            slider
                .slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: false,
                    adaptiveHeight: true,
                    infinite: false,
                    useTransform: true,
                    autoplay: false,
                    lazyLoad: "progressive",
                    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
                    arrows: true,
                    prevArrow: '<div class="slide-arrow prev-arrow"></div>',
                    nextArrow: '<div class="slide-arrow next-arrow"></div>',
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                            },
                        },
                    ],
                })
                .on("beforeChange", function (
                    event,
                    slick,
                    currentSlide,
                    nextSlide
                ) {
                    var calc = (nextSlide / slick.slideCount) * 100;
                    let slide = $(edw_slider_wrap).find(".slick-slide[data-slick-index='" + nextSlide + "']");
                    if (undefined == slide.data("heading")) {
                        slide = $(slide).find(".slide-img-wrap");
                    }
                    headingWrap.text(slide.data("heading"));
                    let tid = slide.data("tabid");
                    let tabs = $(edw_slider_wrap).find("ul.tabs");
                    let tag = $(tabs)
                        .find("[data-tid='" + tid + "']")
                        .data("lbl");
                    let feature_cont_tag = $(slider_popup).find(".feture-cont > .tag");
                    $(feature_cont_tag).text(tag);
                    updateSliderProgress(progress + calc);
                });
            slider.slick("slickGoTo", slideno, true);
        });

        function updateSliderProgress(progress) {
            $progressBar
                .css("background-size", progress + "% 100%")
                .attr("aria-valuenow", progress);
            $progressBarLabel.text(progress + "% completed");
        }
        $("#sn-popup > .btn-cf-popup").click(function () {
            $(".sn-popup").css("display", "none");
            var tab_content_wrap = $(this).closest('.sn-popup'); // get parent element
            let slider = $(tab_content_wrap).find(".ss-cont");
            console.log('slider',slider);
            slider.slick("unslick");
        });
    });

})(jQuery);
