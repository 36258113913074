import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $("#features-popup > .btn-cf-popup").on("click", function () {
        $("#features-popup").css("display", "none");
        $("#feat-img-wrap").slick("unslick");
    });
})(jQuery);
