import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        $(".wp-block-edwiseradvancedblock-testimonialslider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            adaptiveHeight: false,
            infinite: true,
            useTransform: true,
            autoplay: true,
            speed: 900,
            cssEase: "cubic-bezier(0.600, 0.280, 0.735, 0.905)",
            pauseOnHover: true,
            arrows: true,
            prevArrow:
                '<div class="slide-arrow prev-arrow fa fa-2x fa-angle-left"></div>',
            nextArrow:
                '<div class="slide-arrow next-arrow fa fa-2x fa-angle-right"></div>',
        });
        let maxInNav = $(".nav-item").length > 3 ? 3 : $(".nav-item").length;
        $(".slider-nav")
            .on("init", function (event, slick) {
                slick.arrows = false;
                $(".slider-nav .slick-slide.slick-current").addClass(
                    "is-active"
                );
            })
            .slick({
                arrows: false,
                slidesToShow: maxInNav,
                slidesToScroll: 1,
                autoplay: false,
                dots: false,
                focusOnSelect: true,
                infinite: true,
                centerMode: true,
                pauseOnHover: true,
                fade: false,
                centerPadding: 0,
                cssEase: "cubic-bezier(0.600, 0.080, 0.735, 0.045)",
            });
        $(".slider-single").on("afterChange", function (
            event,
            slick,
            currentSlide
        ) {
            $(".slider-nav").slick("slickGoTo", currentSlide);
            var currrentNavSlideElem =
                '.slider-nav .slick-slide[data-slick-index="' +
                currentSlide +
                '"]';
            $(".slider-nav .slick-slide.is-active").removeClass("is-active");
            $(currrentNavSlideElem).addClass("is-active");
        });

        $(".slider-nav").on("click", ".slick-slide", function (event) {
            event.preventDefault();
            var goToSingleSlide = $(this).data("slick-index");

            $(".slider-single").slick("slickGoTo", goToSingleSlide);
        });
    });
})(jQuery);
