import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        /**
         * Popup functionality started.
         */
        let $progressBar = $(".progress");
        let $progressBarLabel = $(".slider__label");

        function showScreenShotPopup(popup) {
            $(popup).css("display", "flex");
            let progress = 0;
            let $slider = $(popup).find(
                ".wp-block-edwiseradvancedblock-slider"
            );
            $slider.on("init", function (event, slick) {
                progress = (1 / slick.slideCount) * 100;
                updateSliderProgress(progress);
            });
            $slider
                .slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: false,
                    adaptiveHeight: true,
                    infinite: false,
                    useTransform: true,
                    autoplay: false,
                    lazyLoad: "progressive",
                    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
                    arrows: true,
                    prevArrow: '<div class="slide-arrow prev-arrow"></div>',
                    nextArrow: '<div class="slide-arrow next-arrow"></div>',
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                            },
                        },
                    ],
                })
                .on("beforeChange", function (
                    event,
                    slick,
                    currentSlide,
                    nextSlide
                ) {
                    var calc = (nextSlide / slick.slideCount) * 100;
                    updateSliderProgress(progress + calc);
                });
        }

        function updateSliderProgress(progress) {
            $progressBar
                .css("background-size", progress + "% 100%")
                .attr("aria-valuenow", progress);

            $progressBarLabel.text(progress + "% completed");
        }
        $(".btn-view-sc").click(function (e) {
            e.preventDefault();
            $(".progress")
                .css("background-size", "0% 100%")
                .attr("aria-valuenow", 0);
            $(".slider__label").text("0% completed");
            let popup = $(this).parent().find(".popup");
            showScreenShotPopup(popup);
        });
        $(".feature-img").click(function () {
            let popup = $(this)
                .parent()
                .children(".feature-text")
                .find(".popup");
            showScreenShotPopup(popup);
        });
        $(".popup >.btn-cf-popup").click(function () {
            $(this).parent().css("display", "none");
            $(this)
                .parent()
                .find(".wp-block-edwiseradvancedblock-slider")
                .slick("unslick");
        });
        /**
         *  Popup functionality ended.
         */
        /**
         *  Slider Functionality Started.
         */

        $(window).resize(function () {
            if ($(window).width() > 768) {
                let enableFeatVisToggler = $(".btn-show-more").data(
                    "visible-all"
                );
                $(".feature-tooggle").show();
                toggleFeatureVisiblity(enableFeatVisToggler);
            } else {
                $(".feature-tooggle").hide();
            }
        });

        /**
         * View More Functionality started.
         */

        function toggleFeatureVisiblity(visibleAll) {
            $(".wp-block-edwiseradvancedblock-featureslider").each(function (
                index
            ) {
                let featuresAVT = $(".btn-show-more").data("vt");
                if (
                    featuresAVT == 0 ||
                    $(".wp-block-edwiseradvancedblock-featureslider").length <=
                        featuresAVT
                ) {
                    $(".feature-tooggle").hide();
                } else {
                    if (visibleAll || featuresAVT == 0) {
                        $(this).show();
                    } else if (index >= featuresAVT) {
                        $(this).hide();
                    }
                    if (index + 1 == featuresAVT) {
                        if (visibleAll) {
                            $(this).removeClass("showmore-partial");
                        } else {
                            $(this).addClass("showmore-partial");
                        }
                    }
                }
            });
        }

        if ($(window).width() > 767) {
            toggleFeatureVisiblity($(".btn-show-more").data("visible-all"));
        } else {
            $(".feature-tooggle").hide();
        }

        $(window).resize(function () {
            if ($(window).width() > 768) {
                $(".feature-tooggle").show();
                toggleFeatureVisiblity($(".btn-show-more").data("visible-all"));
            } else {
                $(".feature-tooggle").hide();
            }
        });

        $("body").on("click", ".btn-show-more", function () {
            if (!$(this).data("hvl")) {
                $(this).data("hvl", true);
                $(this).text("Show Less Features");
                toggleFeatureVisiblity(true);
            } else {
                $(this).data("hvl", false);
                toggleFeatureVisiblity(false);
                $(this).text("Show More Features");
            }
        });
        /**
         * View More Functionality Ended.
         */
    });

    $(window).load(function () {
        $(".fss-img").each(function () {
            $(this).attr("src", $(this).data("src"));
        });
    });
})(jQuery);
