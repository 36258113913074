import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";

    function setFeatureDetials(imgObj) {
        $("#ftsl-titel").text(imgObj.data("title"));
        $("#ftsl-subtitel").text(imgObj.data("subtitle"));
        $("#ftsl-desc").text(imgObj.attr("alt"));
    }

    function updateFeatSlidProgress(progress) {
        let $progressBar = $(".progress");
        let $progressBarLabel = $(".slider__label");
        $progressBar
            .css("background-size", progress + "% 100%")
            .attr("aria-valuenow", progress);
        $progressBarLabel.text(progress + "% completed");
    }

    function startSlider(slideindex) {
        $("#features-popup").css("display", "flex");
        let slider = $("#feat-img-wrap");
        let progress = 0;
        slider
            .on("init", function (event, slick) {
                progress = (1 / slick.slideCount) * 100;
                updateFeatSlidProgress(progress);
            })
            .slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,
                variableWidth: false,
                adaptiveHeight: true,
                infinite: false,
                useTransform: true,
                autoplay: false,
                lazyLoad: "progressive",
                cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
                arrows: true,
                prevArrow: '<div class="slide-arrow prev-arrow"></div>',
                nextArrow: '<div class="slide-arrow next-arrow"></div>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                        },
                    },
                ],
            })
            .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                let calc = (nextSlide / (slick.slideCount)) * 100;
                updateFeatSlidProgress(progress + calc);
            })
            .on("afterChange", function (event, slick, currentSlide) {
                let slide = $("[data-slick-index='" + currentSlide + "']");
                if (
                    slide.find(".feature-slider-img").data("title") != undefined
                ) {
                    slide = slide.find(".feature-slider-img");
                }
                if (slide.attr("src").length < 5) {
                    slide.attr("src", slide.data("lazy"));
                }
                setFeatureDetials(slide);
            })
            .slick("slickGoTo", slideindex, true);
    }

    function getSlideIndex(findex) {
        let slideindex = 0;
        let increment = true;
        $(".feature-slider-img").each(function () {
            if (increment && $(this).data("findex") == findex) {
                increment = false;
            }
            if (increment) {
                slideindex++;
            }
        });
        return slideindex;
    }

    $(".slider_link").on("click", function () {
        startSlider(getSlideIndex($(this).data("findex")));
    });

    $(".feature-img").on("click", function () {
        let findex = $(this).parent().find(".slider_link").data("findex");
        if (findex !== undefined) {
            let slideindex = getSlideIndex(findex);
            startSlider(slideindex);
        }
    });
})(jQuery);
