import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        let popup = $("#wp-block-edwiseradvancedblock-popup");
        $(".btn-block-popup").click(function () {
            $(".block-popup-wrap").show();
        });
        $(".block-popup-close").click(function () {
            $(".block-popup-wrap").hide();
        });
        if (popup) {
            if (popup.hasClass("type_load")) {
                let period = popup.data("delay");
                setTimeout(function () {
                    $(".block-popup-wrap").show();
                }, period);
            }
            if (popup.hasClass("type_selector")) {
                let selector = popup.data("selector");
                $(selector).click(function () {
                    $(".block-popup-wrap").show();
                });
            }
        }
    });
})(jQuery);
