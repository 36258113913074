import "./style.scss";
import jQuery from "jquery";

(function ($) {
    "use strict";
    $(document).ready(function () {
        $(".feature-img-wrap").click(function () {
            $("#wpadminbar").hide(); // hide - admin bar
            $(".site-header").hide(); // hide - header
        });

        $(".sn-popup > .btn-cf-popup").click(function () {
            $("#wpadminbar").show(); // show - admin bar
            $(".site-header").show(); // show - header
        });
    });
})(jQuery);


