import "./blocks/testimonials/script";
// import "./blocks/pricingtable/script";
import "./blocks/featureslider/script";
import "./blocks/sneakpeek/script";
import "./blocks/pluginstats/script";
import "./blocks/headersec/script";
import "./blocks/popup/script";
import "./blocks/card/script";
// import "./blocks/text/script";
import "./blocks/screenshotslider/script";
import "./blocks/slidegotolink/script";
import "./blocks/faq/script";
import "./blocks/advancecard/script";
import "./blocks/demoslider/script";
import "./blocks/screenshotcarousel/script";


